import dayjs from 'dayjs'
import React from 'react'
import { render } from 'react-dom'
import * as watermark from 'watermarkjs'

export const domReady = fn =>
  document.readyState === 'interactive' || document.readyState === 'complete'
    ? fn()
    : document.addEventListener('DOMContentLoaded', fn)

export const addWattermarkImages = () => {
  var options = {
    init: function(img) {
      img.crossOrigin = 'anonymous'
    }
  }

  $('.imageShowCars').map((index, item) => {
    watermark([item.src, '/logo-main1.svg'], options)
      .image(watermark.image.lowerRight(0.2))
      .then(function(img) {
        item.src = img.src
      })
  })
}

export const addOneWattermarkImages = image => {
  var options = {
    init: function(img) {
      img.crossOrigin = 'anonymous'
    }
  }

  image.map((index, item) => {
    watermark([item.src, '/logo-main1.svg'], options)
      // .image(watermark.image.upperLeft(0.2))
      // .load(['/logo-main.svg'])
      .image(watermark.image.lowerRight(0.2))
      .then(function(img) {
        item.src = img.src
      })
    //   watermark([item.src])
    //     .image(
    //       watermark.text.upperLeft('CARHOO', '28px Josefin Slab', '#ccc', 0.5, 48)
    //     )
    //     .render()
    //     .image(
    //       watermark.text.lowerRight('CARHOO', '28px Josefin Slab', '#ccc', 0.5)
    //     )
    //     .then(function(img) {
    //       item.src = img.src
    //     })
  })
}
