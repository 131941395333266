import './components/js/selectChoice'
$(document).ready(function () {
  $('.custom-file-input').change(function () {
    $('.custom-file-label').text(this.value);
  });

  $("#showcase_status_sold").change(function() {
    $("#container-buyer").removeClass("d-none")
  })
  $("#showcase_status_waiting").change(function() {
    $("#container-buyer").addClass("d-none")
  })
  $("#showcase_status_published").change(function() {
    $("#container-buyer").addClass("d-none")
  })
  $("#showcase_status_cancelled").change(function() {
    $("#container-buyer").addClass("d-none")
  })
  $("#showcase_status_expired").change(function() {
    $("#container-buyer").addClass("d-none")
  })
});

$(document).ready(function () {
  if ($('#search_vehicle').length > 0) {
    $('#search_vehicle').on("keyup", function() {
      let license_plate = $(this).val()
      if (license_plate.length == 7) {
        $.ajax({
          url: "/veiculos/search_vehicle/" + license_plate,
          type: "GET"
        }).done(function(data) {
          $(`input[value='${data.condition}']`).prop("checked",true);
          $(`#car_fuel_id_${data.fuel_id}`).prop("checked", true);
          $(`#car_doors_${data.doors}`).prop("checked", true);
          $(`#car_gearbox_${data.gearbox}`).prop("checked", true);

          $(`#manufacture_year`).val(data.manufacture_year);
          $(`#model_year`).val(data.model_year);
          $(`#make_id`).val(data.trim.make_id);
          $(`#model_id`).val(data.trim.model_id);
          $(`#trim_id`).val(data.trim_id);
          $(`#car_engine`).val(data.engine);
          $(`#car_color_id`).val(data.color_id).trigger('change');

          $('.optional_vehicles').prop('checked', false);
          data.optionals.forEach(element => {
            $(`#car_optional_ids_${element.id}`).prop("checked", true);
          });
          
        });
      }
    });
  }
});

$(document).ready(function(){
  $("#search_input").on("keyup", function() {
    let value = $(this).val().toLowerCase();
    $("#list_publications .item_search").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });

  $("#make").on("change", function() {
    let id_value = $(this).val().toLowerCase();
    let value = $("#make option:selected").text().toLowerCase();
    clear_select('#model', 'Modelo')
    clear_select('#trim', 'Versão')
    if(value == 'marca'){
      value = ''
    }
    if (id_value) {
      $.ajax({
        url: "/get_model/" + id_value,
        type: "GET"
      }).done(function(data) {
        console.log(data)
        let model, i, len;
        for (i = 0, len = data.length; i < len; i++) {
          model = data[i];
          $('#model').append("<option value='" + model[0] + "'>" + model[1] + "</option>");
        }
      });
    }
    $("#list_publications .item_search").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });

  $("#model").on("change", function() {
    let id_value = $(this).val().toLowerCase();
    let value = $("#model option:selected").text().toLowerCase();
    clear_select('#trim', 'Versão')
    if(value == 'modelo'){
      value = ''
    }
    if (id_value) {
      $.ajax({
        url: "/get_trim/" + id_value,
        type: "GET"
      }).done(function(data) {
        let trim, i, len;
        for (i = 0, len = data.length; i < len; i++) {
          trim = data[i];
          $('#trim').append("<option value='" + trim[0] + "'>" + trim[1] + "</option>");
        }
      });
    }
    $("#list_publications .item_search").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });

  $("#trim").on("change", function() {
    let value = $("#trim option:selected").text().toLowerCase();
    if(value == 'versão'){
      value = ''
    }
    $("#list_publications .item_search").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
    });
  });

  const clear_select = (id, name) => {
    $(id).html('')
    $(id).append("<option value=''>" + name + "</option>");
  };
});



$(document).ready(function () {
  $('#publication_model').hide()
  $('#publication_trim').hide()
  $('#publication_make_select').change(function () {
    $.get("/assinatura/publications/search_make?id=" + $('#publication_make_select').val(), function (data) {
      $('#publication_model').show()
      if (document.getElementById('model_list').options.length != 1) {
        $('#model_list')
          .find('option')
          .remove()
          .end()
          .append('<option value="">Escolha o Modelo</option>')
          .val()
        $('#publication_trim').hide()
      }
      const myParent = document.getElementById("publication_model")
      const selectList = document.getElementById("model_list");
      myParent.appendChild(selectList);
      for (var i = 0; i < data.models.length; i++) {
        let option = document.createElement("option");
        option.value = data.models[i].id;
        option.text = data.models[i].name;
        selectList.appendChild(option);
      }
    })
  })
})

$(document).ready(function () {
  $('#model_list').change(function () {
    $.get("/assinatura/publications/search_trim?id=" + $('#model_list').val(), function (data) {
      $('#publication_trim').show()
      if (document.getElementById('trim_list').options.length != 1) {
        $('#trim_list')
          .find('option')
          .remove()
          .end()
          .append('<option value="">Escolha a Versão</option>')
          .val()
      }
      var myParent = document.getElementById("publication_trim")
      var selectList = document.getElementById("trim_list");
      myParent.appendChild(selectList);
      for (var i = 0; i < data.trims.length; i++) {
        var option = document.createElement("option");
        option.value = data.trims[i].id;
        option.text = data.trims[i].name;
        selectList.appendChild(option);
      }
    })
  })
})

$(document).ready(function () {
  $('#trim_list').change(function () {
    $('.trim_field').val($('#trim_list').val())
  })
})


$(document).ready(function () {
  $('#faq-question-0').addClass('active');
  $('#faq-response-0').slideToggle('fast');
  $('h5.accordion').click(function () {
    $(this).toggleClass('active');
    $(this).parent().find('p.accordion').slideToggle('fast');
  });
});


$(document).ready(function () {
  $.get("/politica-de-privacidade", function (data) {
    $('#privacy_policy_title').val(data.title)
    $('#privacy_policy_description').val(data.description)
  })
})


$(document).ready(() => {
  $('.button-modal-publication').click((e) => {
    $('.publication_field_id').val(($(e.target).attr('data-value')))
  })
})

window.setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires;
  $('#privacy_policy').removeClass('d-flex')
  $('#privacy_policy').addClass('d-none')
};

window.getCookie = (cname) => {
  const name = cname + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1);
    if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
  }
  return "";
};

window.refreshPage = () => {
  window.location.reload();
}

$(document).ready(function () {
  let chart = document.getElementById('arremateChart');
  if(chart) {
    let data = JSON.parse($(chart).attr("data"));
    let arremateChart = new Chart(chart, {
      type: 'horizontalBar',
      data: data,
      options: {
        scales: {
            xAxes: [{
                ticks: {
                  beginAtZero: true,
                  maxTicksLimit: 25,
                  stepSize: 1
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Arremates'
                }
            }],
            yAxes: [{
              scaleLabel: {
                display: true,
                labelString: 'Lances'
              }
          }]
        }
      }
    });  
  }
});