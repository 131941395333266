import { domReady } from '../../utils'

const selectKindClientForForm = () => {
  let selected = document.querySelector("input[name='client[kind]']:checked")
  let selected_schedulings = document.querySelector("input[name='scheduling[delivery]']:checked")

  hideAllFields()

  if(selected_schedulings){
    $('.addressScheduling').show()
  }
  
  if (selected) {
    showHideFields(selected.value)
  }

  $('.clientKind').on('change', "input[name='client[kind]']", function() {
    let current_selected = document.querySelector("input[name='client[kind]']:checked").value

    showHideFields(current_selected)
  })
  
  $('.schedulingKind').on('change', "input[name='scheduling[delivery]']", function() {
    let current_selected = document.querySelector("input[name='scheduling[delivery]']:checked").value
  
    if(current_selected == 'true') {
      console.log("show")
      $('.addressScheduling').show()
    }else {
      console.log("hide")
      $('.addressScheduling').hide()
    }
  })
}


const showHideFields = (selected_value) => {
  switch (selected_value) {
    case 'individual' :
      $('.corporate').hide()
      $('.dealer').hide()
      $('.shopkeeper').hide()
      $('.individual').show()
      $('#broker_form').val(false)
      break
    case 'dealer' :
      $('.corporate').show()
      $('.dealer').show()
      $('.shopkeeper').hide()
      $('.individual').hide()
      $('#broker_form').val(false)
      break
    case 'shopkeeper' :
      $('.corporate').show()
      $('.shopkeeper').show()
      $('.dealer').hide()
      $('.individual').hide()
      $('#broker_form').val(false)
      break
    case 'broker' :
      $('.corporate').hide()
      $('.dealer').hide()
      $('.shopkeeper').hide()
      $('.individual').show()
      $('#broker_form').val(false)
      break
    default :
      $('.corporate').show()
      $('.dealer').hide()
      $('.shopkeeper').hide()
      $('.individual').hide()
      $('#broker_form').val(false)
  }
}

const hideAllFields = () => {
  $('.corporate').hide()
  $('.dealer').hide()
  $('.individual').hide()
  $('.shopkeeper').hide()
  $('.addressScheduling').hide()
}

export default domReady(selectKindClientForForm)