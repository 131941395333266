import bootstrap from 'bootstrap'
import initRailsUjs from './rails-ujs'
import initFancybox from './fancybox'
import initFeather from './feather'
import initTippy from './tippy'
import initWOW from './wow'
import initVmasker from './vmasker'
import './pnotify'
import './cocoon'

$(document).ready(function() {

  $('#section_fields_insert').on("cocoon:after-insert", function() {
    const ckurl = $("#ckeditor-url").attr("data-value")
    $(this).children().each((index, el) => {
      const id = $(el).find(".form-description-section").attr("id")
      if(id != "lgpd_setting_lgpd_sections_attributes_0_content") {
        setTimeout(function(){
          CKEDITOR.replace(document.getElementById(id), {"customConfig": ckurl} ); //Your selector must match the textarea ID
        },400);
      }
    })
  })

  $('#lots').on('cocoon:after-insert', function() {
    const last_vehicles_client = $('.vehicles_client').last()
    const last_vehicles_price_alert = $('.vehicles_price_alert').last()

    last_vehicles_client.change(function () {
      last_vehicles_price_alert.html('')
      $.get("/veiculos/" + $(this).val() + "/vehicle_price/", {}, function (data) {
        last_vehicles_price_alert.html(data.info)
      })
    })

    $.get("/clientes/" + $('.client_store_lots').val() + "/veiculos/", {}, function (data) {
      last_vehicles_client.html('')
      last_vehicles_price_alert.html('')
      var newOption = new Option('Selecione o Veículo', '', false, false);
      last_vehicles_client.append(newOption)
      data.forEach(function (element) {
        var newOption = new Option(element[1], element[0], false, false);
        // Append it to the select
        last_vehicles_client.append(newOption)
      });
    })
  })

  $('.nested_grups').on('cocoon:after-insert', function(e, insertedItem) {
    console.log('teste')
    $('.select2').select2({
      theme: 'bootstrap4',
      language: 'pt-BR'
    })
  })

});