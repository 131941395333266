import compose from 'lodash/fp/compose'
import VMasker from 'vanilla-masker'
import { domReady } from '../utils'

const inputHandler = (masks, max, event) => {
  let c = event.target;
  let v = c.value.replace(/\D/g, '');
  let m = c.value.length > max ? 1 : 0;
  VMasker(c).unMask();
  VMasker(c).maskPattern(masks[m]);
  c.value = VMasker.toPattern(v, masks[m]);
}

const vmaskerPhones = () => {
  const tels = Array.from(document.querySelectorAll(".phone"))

  if (tels) {
    tels.map(tel => {
      let telMask = ['(99) 9999-99999', '(99) 99999-9999']
      VMasker(tel).maskPattern(telMask[0])
      tel.addEventListener('input', inputHandler.bind(undefined, telMask, 14), false)
    })
  }
}

export const vmaskerDdds = () => {
  const ddds = Array.from(document.querySelectorAll(".ddd"))

  if (ddds) {
    ddds.map(ddd => {
      VMasker(ddd).maskPattern('99')
    })
  }
}

export const vmaskerPhoneNums = () => {
  const tels = Array.from(document.querySelectorAll(".phoneNumber"))

  if (tels) {
    tels.map(tel => {
      let telMask = ['9999-9999', '99999-9999']
      if (tel.value.length === 9) {
        VMasker(tel).maskPattern(telMask[0])
      } else {
        VMasker(tel).maskPattern(telMask[1])
      }
      tel.addEventListener('input', inputHandler.bind(undefined, telMask, 9), false)
    })
  }
}

const vmaskerDocs = () => {
  const docs = Array.from(document.querySelectorAll(".doc"))

  if (docs) {
    docs.map(doc => {
      let docMask = ['999.999.999-999', '99.999.999/9999-99']
      VMasker(doc).maskPattern(docMask[0])
      doc.addEventListener('input', inputHandler.bind(undefined, docMask, 14), false)
    })
  }
}

const vmaskerPlates = () => {
  const plates = Array.from(document.querySelectorAll(".fieldLicensePlate"))

  if (plates) {
    plates.map(plate => {
      VMasker(plate).maskPattern('AAA9SS9')
    })
  }
}

const vmaskerCEP = () => {
  const ceps = Array.from(document.querySelectorAll(".cep"))

  if (ceps) {
    ceps.map(cep => {
      VMasker(cep).maskPattern('99999-999')
    })
  }
}

const vmaskerCPF = () => {
  const cpfs = Array.from(document.querySelectorAll(".cpf"))

  if (cpfs) {
    cpfs.map(cpf => {
      VMasker(cpf).maskPattern('999.999.999-99')
    })
  }
}
const vmaskerCNPJ = () => {
  const cnpjs = Array.from(document.querySelectorAll(".cnpj"))

  if (cnpjs) {
    cnpjs.map(cnpj => {
      VMasker(cnpj).maskPattern('99.999.999/9999-99')
    })
  }
}

export const refreshVMasker = compose(
  vmaskerPhones,
  vmaskerDocs,
  vmaskerPlates,
  vmaskerDdds,
  vmaskerPhoneNums,
  vmaskerCEP,
  vmaskerCPF,
  vmaskerCNPJ
)

export default domReady(
  compose(
    vmaskerPhones,
    vmaskerDocs,
    vmaskerPlates,
    vmaskerDdds,
    vmaskerPhoneNums,
    vmaskerCEP,
    vmaskerCPF,
    vmaskerCNPJ
  )
)