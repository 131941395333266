import 'webpack-jquery-ui'
import * as List from 'list.js'
import * as watermark from 'watermarkjs'
import Chart from 'chart.js'
import { addWattermarkImages } from '../src/utils'

// INDEX.JS
import 'select2/dist/js/select2.full.min'
import 'select2/dist/js/i18n/pt-BR'
import '../src' // Support component names relative to this directory:
import '../src/main' // Support component names relative to this directory:

var componentRequireContext = require.context('../src/components/react', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

// select2
$(document).ready(function() {
  addWattermarkImages()
  $('.select2').select2({
    theme: 'bootstrap4',
    language: 'pt-BR'
  })

  $('.select-model-trim').change(function() {
    const trim_id = $(this).attr('data-id')
    const model_id = $(this).val()
    $.get('/versoes/update_model/' + trim_id + '/' + model_id, {}, function(
      data
    ) {
      // console.log(data)
    })
  })

  $('.select-make-trim').change(function() {
    const trim_id = $(this).attr('data-id')
    const make_id = $(this).val()
    $.get('/modelos/makes/' + make_id, {}, function(data) {
      // console.log(data)
      $(`#model_${trim_id}`).html('')
      let newOption = new Option('Selecione o Modelo', '', true, false)
      $(`#model_${trim_id}`).append(newOption)
      data.forEach(function(element) {
        let newOption = new Option(element.name, element.id, false, false)
        // Append it to the select
        $(`#model_${trim_id}`).append(newOption)
      })
    })
  })

  $('#make_trim').change(function() {
    const make_id = $(this).val()
    $(`#model_trim`).html('')
    $.get('/modelos/makes/' + make_id, {}, function(data) {
      // console.log(data)
      let newOption = new Option('Selecione o Modelo', '', true, false)
      $(`#model_trim`).append(newOption)
      data.forEach(function(element) {
        let newOption = new Option(element.name, element.id, false, false)
        // Append it to the select
        $(`#model_trim`).append(newOption)
      })
    })
  })

  $('#vehicle_type_Carro').click(function() {
    if ($('#vehicle_type_Carro').is(':checked')) {
      $(`#model_make_id`).html('')
      $.get('/marcas?tipo=carro', {}, function(data) {
        // console.log(data)
        let newOption = new Option('Selecione a Marca', '', true, false)
        $(`#model_make_id`).append(newOption)
        data.forEach(function(element) {
          let newOption = new Option(element[1], element[0], false, false)
          // Append it to the select
          $(`#model_make_id`).append(newOption)
        })
      })
    }
  })

  $('#vehicle_type_Moto').click(function() {
    if ($('#vehicle_type_Moto').is(':checked')) {
      $(`#model_make_id`).html('')
      $.get('/marcas?tipo=moto', {}, function(data) {
        // console.log(data)
        let newOption = new Option('Selecione a Marca', '', true, false)
        $(`#model_make_id`).append(newOption)
        data.forEach(function(element) {
          let newOption = new Option(element[1], element[0], false, false)
          // Append it to the select
          $(`#model_make_id`).append(newOption)
        })
      })
    }
  })

  $('#optional_vehicle_type_car').click(function() {
    if ($('#optional_vehicle_type_car').is(':checked')) {
      $(`#optional_make_id`).html('')
      $.get('/marcas?tipo=carro', {}, function(data) {
        // console.log(data)
        let newOption = new Option('Selecione a Marca', '', true, false)
        $(`#optional_make_id`).append(newOption)
        data.forEach(function(element) {
          let newOption = new Option(element[1], element[0], false, false)
          // Append it to the select
          $(`#optional_make_id`).append(newOption)
        })
      })
    }
  })

  $('#optional_vehicle_type_motorcycle').click(function() {
    if ($('#optional_vehicle_type_motorcycle').is(':checked')) {
      $.get('/marcas?tipo=moto', {}, function(data) {
        // console.log(data)
        $(`#optional_make_id`).html('')
        let newOption = new Option('Selecione a Marca', '', true, false)
        $(`#optional_make_id`).append(newOption)
        data.forEach(function(element) {
          let newOption = new Option(element[1], element[0], false, false)
          // Append it to the select
          $(`#optional_make_id`).append(newOption)
        })
      })
    }
  })

  const options = {
    valueNames: ['name']
  }

  const userList = new List('optional_list', options)
  const extraList = new List('extra_list', options)
  // console.log(userList)

  $('#save_vehicle').mouseover(() => {
    $('#search_optionals').val('')
    userList.search()
  })
})

$(document).ready(function() {
  $('.select2AjaxGetClients').select2({
    theme: 'bootstrap4',
    language: 'pt-BR',
    minimumInputLength: 3,
    maximumInputLength: 20,
    ajax: {
      // url: $('.select2-field').data('endpoint'),
      url: '/clientes/search.json',
      dataType: 'json',
      delay: 250,
      data: function(params) {
        return {
          q: params.term,
          page: params.page
        }
      },
      processResults: function(data) {
        return {
          results: $.map(data, function(item) {
            return {
              text: item.name,
              id: item.id
            }
          })
        }
      }
    }
  })
})
