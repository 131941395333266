$(function() {
  'use strict'

  $(function() {
    $('.preloader').fadeOut()
  })

  // ==============================================================
  // This is for the top header part and sidebar part
  // ==============================================================
  var set = function() {
    var width = window.innerWidth > 0 ? window.innerWidth : this.screen.width
    var topOffset = 70
    if (width < 1170) {
      $('body').addClass('mini-sidebar')
      $('.navbar-brand span').hide()
      $('.scroll-sidebar, .slimScrollDiv')
        .css('overflow-x', 'visible')
        .parent()
        .css('overflow', 'visible')
      // $('.sidebartoggler i').addClass('ti-menu')
    } else {
      $('body').removeClass('mini-sidebar')
      $('.navbar-brand span').show()
      //$(".sidebartoggler i").removeClass("ti-menu");
    }

    var height =
      (window.innerHeight > 0 ? window.innerHeight : this.screen.height) - 1
    height = height - topOffset
    if (height < 1) height = 1
    if (height > topOffset) {
      $('.page-wrapper').css('min-height', height + 'px')
    }
  }
  $(window).ready(set)
  $(window).on('resize', set)

  // ==============================================================
  // Theme options
  // ==============================================================
  $('.sidebartoggler').on('click', function() {
    if ($('body').hasClass('mini-sidebar')) {
      $('body').trigger('resize')
      $('.scroll-sidebar, .slimScrollDiv')
        .css('overflow', 'hidden')
        .parent()
        .css('overflow', 'visible')
      $('body').removeClass('mini-sidebar')
      $('.navbar-brand span').show()
      //$(".sidebartoggler i").addClass("ti-menu");
    } else {
      $('body').trigger('resize')
      $('.scroll-sidebar, .slimScrollDiv')
        .css('overflow-x', 'visible')
        .parent()
        .css('overflow', 'visible')
      $('body').addClass('mini-sidebar')
      $('.navbar-brand span').hide()
      //$(".sidebartoggler i").removeClass("ti-menu");
    }
  })
  // topbar stickey on scroll

  $('.fix-header .topbar').stick_in_parent({})

  // this is for close icon when navigation open in mobile view
  $('.nav-toggler').click(function() {
    $('body').toggleClass('show-sidebar')
    // $('.nav-toggler i').toggleClass('ti-menu')
    // $('.nav-toggler i').addClass('ti-close')
  })
  $('.sidebartoggler').on('click', function() {
    //$(".sidebartoggler i").toggleClass("ti-menu");
  })


  // ==============================================================
  // Auto select left navbar
  // ==============================================================
  $(function() {
    var url = window.location
    var element = $('ul#sidebarnav a')
      .filter(function() {
        return this.href == url
      })
      .addClass('active')
      .parent()
      .addClass('active')
    while (true) {
      if (element.is('li')) {
        element = element
          .parent()
          .addClass('in')
          .parent()
          .addClass('active')
      } else {
        break
      }
    }
  })

  // ==============================================================
  // Sidebarmenu
  // ==============================================================
  $(function() {
    $('#sidebarnav').metisMenu()
  })

  // ==============================================================
  // Slimscrollbars
  // ==============================================================
  $('.scroll-sidebar').slimScroll({
    position: 'left',
    size: '5px',
    height: '100%',
    color: '#dcdcdc'
  })

  $('.message-center').slimScroll({
    position: 'right',
    size: '5px',

    color: '#dcdcdc'
  })

  // ==============================================================
  // Resize all elements
  // ==============================================================
  $('body').trigger('resize')
})
