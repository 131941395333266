import React, { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { featureImage, createImage, getImages, updateImage, deleteImage } from './services'
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc'
import arrayMove from 'array-move'

function VehicleDropzone(props) {
  const [vehiclePictures, setVehiclePictures] = useState([])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*',
    onDrop: async acceptedFiles => {
      acceptedFiles.map(async (item, index) => {
        const imageData = new FormData()
        imageData.append(`image[file]`, item)
        imageData.append('image[imageable_id]', props.vehicleId)
        imageData.append('image[imageable_type]', 'Vehicle')

        await createImage(imageData)
        await setImages()
      })
    },
    onDropRejected: event => {
      alert(`Só aceitamos imagens.`)
    }
  })

  const setImages = async () => {
    const response = await getImages(props.vehicleId)

    setVehiclePictures(response.data)
  }

  const featuredImage = async id => {
    const response = await featureImage(id, props.vehicleId)
    await setImages()
  }

  useEffect(() => {
    const updateImages = async () => {
      await setImages()
    }

    updateImages()
  }, [])

  useEffect(() => {
    updateImagePositions()
  }, [vehiclePictures])

  const removePicture = async id => {
    await deleteImage(id)
    await setImages()
  }

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // const atualPicture = pictures[oldIndex] // Armazena a imagem movida para verificar se deve setar principal
    const array = arrayMove(vehiclePictures, oldIndex, newIndex)
    setVehiclePictures(array)
    updateImagePositions(array)
  }

  const updateImagePositions = (array = vehiclePictures) => {
    array.map(async (e, index) => {
      // Se a imagem estiver no indice 0, deverá ser setada como principal
      index === 0 && !e.main && featuredImage(e.id)
      await updateImage(e.id, index)
    })
  }

  const ImageContainer = SortableElement(props => {
    const { pic, index } = props
    return <>{props.shouldUseDragHandle && <Image pic={pic} />}</>
  })

  const Image = SortableHandle(({ index, pic }) => (
    <div
      className={pic.main ? 'imagePreview active' : 'imagePreview'}
      key={index}
    >
      <div className="removeImage" onClick={() => removePicture(pic.id)}>
        <i className="mdi mdi-close"></i>
      </div>
      <img src={pic.file_url} />
      {pic.main ? <div className="labelMainImage">Foto principal</div> : false}
      <span className="textInfo">
        Arraste e solte para redefinir a ordem de exibição. A primeira imagem é sempre a principal!
      </span>
    </div>
  ))

  const ImageList = SortableContainer(props => {
    const { items, ...restProps } = props
    return (
      <div>
        {items.map((pic, index) => (
          <ImageContainer
            key={`item-${index}`}
            pic={pic}
            index={index}
            {...restProps}
          />
        ))}
      </div>
    )
  })

  return (
    <React.Fragment>
      <div
        className={`dropzoneInput ${isDragActive ? 'dropzoneInputActive' : ''}`}
        {...getRootProps()}
      >
        <React.Fragment>
          <input {...getInputProps()} />
          {isDragActive ? (
            <span>Solte...</span>
          ) : (
            <span>Arraste e solte as fotos aqui...</span>
          )}
        </React.Fragment>
      </div>
      <div>
        <div className="containerImages">
          <ImageList
            shouldUseDragHandle={true}
            useDragHandle
            axis="xy"
            items={vehiclePictures}
            onSortEnd={onSortEnd}
            distance={1}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

export default VehicleDropzone
