$(document).ready(function() {
  $('#dynamicModal').on('hidden.bs.modal', function (e) {
    document.getElementById("dynamicModal").querySelector(".modal-content").innerHTML = ""
  })

  $('#modal_nf').on('show.bs.modal', function (event) {
    const button = $(event.relatedTarget)
    const lot_rate = button.data('lot-rate')
    const lot = button.data('lot')
    const url_rate = '/gerenciador-financeiro/lote_rate/'+lot_rate+'/nota_fiscal'

    const modal = $(this)
    modal.find('.modal-title').text('Editar NF da taxa do lote ' + lot)
    modal.find('.modal-body form').attr("action", url_rate)
    modal.find('.modal-body .lot_rate_invoice').val('')
  })

  window.addEventListener('load', () => {
    const element = document.querySelector("#form_update_lot_rate")

    if (element) {
      element.addEventListener("ajax:success", (event) => {
        const [_data, _status, xhr] = event.detail
        $('#modal_nf').modal('hide')
      })
      element.addEventListener("ajax:error", () => {
        element.insertAdjacentHTML('beforeend', "<p>ERROR</p>")
      })
    }
  })
})
