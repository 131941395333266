import { create } from 'apisauce'

const api = create({
  baseURL: process.env.API_SERVICES,
  headers: { Accept: 'application/json' },
})

export const createImage = (image) => api.post('/images', image)
export const getImages = (vehicleId) => api.get(`/images?vehicle_id=${vehicleId}`)
export const deleteImage = (id) => api.delete(`/images/${id}`)
export const featureImage = (id, vehicleId) => api.put(`/images/${id}/feature_image?vehicle_id=${vehicleId}`, {})
export const updateImage = async (id, position) => await api.put(`/images/${id}`, { image: { position } })

export const get_models = (modelYear, make) => api.get(`/models/get_models?model_year=${modelYear}&make=${make}`)
export const get_trims = (modelYear, model) => api.get(`/trims/get_trims?model_year=${modelYear}&model=${model}`)
export const get_cities = (state) => api.get(`/states/get_cities?state_id=${state}`)

export const get_infos_plate = (plate) => api.get(`/precifications/vehicle_by_plate?plate=${plate}`)

