import './initializers'
import './components/js'
import './components/react'
import './template'
window.moment = require('moment');

$('.client_store_lots').change(function () {
  $('.add_field').removeClass('d-none')
  $.get("/clientes/" + $(this).val() + "/veiculos/", {}, function (data) {
    $('.vehicles_client').html('')
    $('.vehicles_price_alert').html('')
    var newOption = new Option('Selecione o Veículo', '', false, false);
    $('.vehicles_client').append(newOption)
    data.forEach(function (element) {
      var newOption = new Option(element[1], element[0], false, false);
      // Append it to the select
      $('.vehicles_client').append(newOption)
    });
  })
})

$('#origin_id').on("change", function () {
  let indication = $(this).val();

  if (indication && $(this).find('option[value='+indication+']').attr('data-value') == "true"){
    $('#indicated_by_field').addClass('show')
    $('#indicated_by_field').removeClass('hide')
  } else {
    $('#indicated_by_field').addClass('hide')
    $('#indicated_by_field').removeClass('show')
  }
})