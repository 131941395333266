$(document).ready(function() {
  $(".button-helper-contract").click(function(e){
    let text = $(this)[0].getAttribute("data-key")
    insertAtCursor(text)
  }); 
})

function insertAtCursor(text) {
  for(var i in CKEDITOR.instances) {
    CKEDITOR.instances.system_setup_brokerage_contract.insertText(text);
  }
} 